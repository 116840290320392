var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hovering__pad py-3"},[_c('div',{staticClass:"px-3 mb-3 d-flex"},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":"outline-default"},on:{"click":function($event){return _vm.$bvModal.show('create-meta')}}},[_vm._v("Добавить")])],1),(_vm.data)?_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.data,"stacked":"sm","responsive":""},scopedSlots:_vm._u([{key:"cell(_id)",fn:function(data){return [_vm._v(_vm._s(data.value.$oid))]}},{key:"cell(del)",fn:function(data){return [_c('b-button',{staticStyle:{"height":"33.5px"},attrs:{"variant":"outline-default"},on:{"click":function () {
            _vm.model = data.item;
            _vm.processMeta('delete');
          }}},[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("@main/assets/img/svg/redcross.svg"),"alt":""}})])]}},{key:"cell(edit)",fn:function(data){return [_c('b-button',{attrs:{"variant":"outline-default"},on:{"click":function () {
            _vm.action = 'update';
            _vm.model = data.item;
            _vm.$bvModal.show('create-meta');
          }}},[_vm._v("✏️")])]}},{key:"cell(icon)",fn:function(data){return [(data.value)?_c('img',{staticStyle:{"width":"45px","height":"45px"},attrs:{"src":data.value,"alt":""}}):_c('p',[_vm._v("Нет иконки")])]}}],null,false,2990281720)}):_c('loader'),_c('b-modal',{attrs:{"size":"lg","id":"create-meta"},on:{"ok":function () { return _vm.processMeta(_vm.action); }}},[_c('meta-data-form',{ref:"mtf",attrs:{"metadata":_vm.schema},scopedSlots:_vm._u([{key:"available_from",fn:function(ref){
          var field = ref.field;
          var validations = ref.validations;
return [_c('label',[_vm._v("Доступен с определенной даты:")]),_c('date-time-input',{class:{ error: validations.model[field[0]].$anyError },model:{value:(validations.model[field[0]].$model),callback:function ($$v) {_vm.$set(validations.model[field[0]], "$model", $$v)},expression:"validations.model[field[0]].$model"}})]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }