<template>
  <div>
    <b-form-datepicker
      locale="ru"
      placeholder="Дата"
      v-model="date"
      :class="$attrs.class"
    ></b-form-datepicker>
    <b-form-timepicker
      locale="ru"
      placeholder="Время"
      v-model="time"
      :class="$attrs.class"
    ></b-form-timepicker>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  computed: {
    time: {
      get() {
        if (!this.value || typeof this.value !== 'string') {
          return;
        }
        try {
          return this.value.split('T')[1].split('.')[0];
        } catch (e) {
          console.error(e);
          return null;
        }
      },
      set(value) {
        let splitValue;
        if (!this.value || typeof this.value !== 'string') {
          splitValue = [null, null];
        } else {
          splitValue = this.value.split('T');
        }
        splitValue[1] = `${value}.000000`;
        this.$emit('input', splitValue.join('T'));
      },
    },
    date: {
      get() {
        if (!this.value || typeof this.value !== 'string') {
          return;
        }
        return this.value.split('T')[0];
      },
      set(value) {
        let splitValue;
        if (!this.value || typeof this.value !== 'string') {
          splitValue = [null, null];
        } else {
          splitValue = this.value.split('T');
        }
        splitValue[0] = value;
        this.$emit('input', splitValue.join('T'));
      },
    },
  },
};
</script>
